import { ProfileRankType } from '@/constants/Survey';
import { SurveyState } from '@/stores/survey';

export enum Type {
  PAIR_RANK = 'pair_rank',
  SIMPLE_PAIR_RANK = 'simple_pair_rank',
  ORDER_RANK = 'order_rank',
  DOWDALL_ORDER_RANK = 'dowdall_order_rank',
  POINTS_RANK = 'points_rank',
  AGREEMENT_RANK = 'agreement_rank',
  BEST_WORST_RANK = 'best_worst_rank',
  PROFILE_RANK = 'profile_rank',
  MULTIPLE_CHOICE = 'multiple_choice',
  RATING_SCALE = 'rating_scale',
  TEXT_RESPONSE = 'text_response',
  EMAIL_COLLECTION = 'email_collection',
  PHONE_COLLECTION = 'phone_collection',
  USERNAME_COLLECTION = 'username_collection',
  NAME_COLLECTION = 'name_collection',
  CUSTOM_IDENTIFY_COLLECTION = 'custom_identify_collection',
  HEADER = 'header',
}

export const typeIcon: Record<Type, string> = {
  [Type.AGREEMENT_RANK]: 'mdi-thumb-up-outline',
  [Type.BEST_WORST_RANK]: 'mdi-scale-unbalanced',
  [Type.PROFILE_RANK]: 'mdi-cards',
  [Type.MULTIPLE_CHOICE]: 'mdi-radiobox-marked',
  [Type.RATING_SCALE]: 'mdi-star-box',
  [Type.DOWDALL_ORDER_RANK]: 'mdi-podium',
  [Type.ORDER_RANK]: 'mdi-podium',
  [Type.PAIR_RANK]: 'mdi-compare-horizontal',
  [Type.SIMPLE_PAIR_RANK]: 'mdi-compare-horizontal',
  [Type.POINTS_RANK]: 'mdi-cash-plus',
  [Type.TEXT_RESPONSE]: 'mdi-form-textbox',
  [Type.CUSTOM_IDENTIFY_COLLECTION]: 'mdi-rename-box',
  [Type.EMAIL_COLLECTION]: 'mdi-email',
  [Type.PHONE_COLLECTION]: 'mdi-phone',
  [Type.NAME_COLLECTION]: 'mdi-account',
  [Type.USERNAME_COLLECTION]: 'mdi-card-account-details',
  [Type.HEADER]: 'mdi-card-text',
} as const;

export const typeDisplayName: Record<Type, string> = {
  [Type.AGREEMENT_RANK]: 'Agreement Rank',
  [Type.BEST_WORST_RANK]: 'Best/Worst Rank',
  [Type.PROFILE_RANK]: 'Profile Rank',
  [Type.MULTIPLE_CHOICE]: 'Multiple Choice',
  [Type.RATING_SCALE]: 'Rating Scale',
  [Type.DOWDALL_ORDER_RANK]: 'Order Rank',
  [Type.ORDER_RANK]: 'Order Rank',
  [Type.PAIR_RANK]: 'Pair Rank',
  [Type.SIMPLE_PAIR_RANK]: 'Pair Rank',
  [Type.POINTS_RANK]: 'Points Rank',
  [Type.TEXT_RESPONSE]: 'Text Response',
  [Type.CUSTOM_IDENTIFY_COLLECTION]: 'Identify',
  [Type.EMAIL_COLLECTION]: 'Email',
  [Type.PHONE_COLLECTION]: 'Phone',
  [Type.NAME_COLLECTION]: 'Name',
  [Type.USERNAME_COLLECTION]: 'Username',
  [Type.HEADER]: 'Header',
} as const;

export const typeOpinionFieldMap: Record<RankingType, `${string}.${string}`> = {
  [Type.AGREEMENT_RANK]: 'agreementRank.agreement',
  [Type.DOWDALL_ORDER_RANK]: 'dowdallOrderRank.rating',
  [Type.ORDER_RANK]: 'orderRank.rating',
  [Type.PAIR_RANK]: 'pairRank.rating',
  [Type.POINTS_RANK]: 'pointsRank.score',
  [Type.SIMPLE_PAIR_RANK]: 'simplePairRank.score',
  [Type.BEST_WORST_RANK]: 'bestWorstRank.score',
  [Type.PROFILE_RANK]: 'profileCategoryRank.score',
};

export const getTypeOpinionField = (
  stepType: RankingType,
  profileRankType?: ProfileRankType,
): `${string}.${string}` => {
  if (profileRankType) {
    if (profileRankType === ProfileRankType.CATEGORY) {
      return 'profileCategoryRank.score';
    } else if (profileRankType === ProfileRankType.OPTION) {
      return 'profileOptionRank.score';
    }
  }
  return typeOpinionFieldMap[stepType];
};

export const rankingStepTypes = [
  Type.PAIR_RANK,
  Type.SIMPLE_PAIR_RANK,
  Type.ORDER_RANK,
  Type.DOWDALL_ORDER_RANK,
  Type.POINTS_RANK,
  Type.AGREEMENT_RANK,
  Type.BEST_WORST_RANK,
  Type.PROFILE_RANK,
] as const;

export type RankingType = (typeof rankingStepTypes)[number];

export const segmentableStepTypes = [
  ...rankingStepTypes,
  Type.RATING_SCALE,
  Type.MULTIPLE_CHOICE,
] as const;

export type SegmentableStepType = (typeof segmentableStepTypes)[number];

export const isSegmentableStepType = (
  type: unknown,
): type is SegmentableStepType => {
  return (
    typeof type === 'string' &&
    segmentableStepTypes.includes(type as SegmentableStepType)
  );
};

export const identifyCollectionStepTypes = [
  Type.EMAIL_COLLECTION,
  Type.NAME_COLLECTION,
  Type.USERNAME_COLLECTION,
  Type.PHONE_COLLECTION,
  Type.CUSTOM_IDENTIFY_COLLECTION,
] as const;

export type IdentifyCollectionStep =
  (typeof identifyCollectionStepTypes)[number];

export const boundedStepTypes = [
  Type.MULTIPLE_CHOICE,
  Type.RATING_SCALE,
] as const;

export type BoundedStep = (typeof boundedStepTypes)[number];

export const editableStepTypes = [
  Type.MULTIPLE_CHOICE,
  Type.RATING_SCALE,
  ...identifyCollectionStepTypes,
] as const;

export type EditableStep = (typeof editableStepTypes)[number];

const editableStepPriority: {
  [key in EditableStep]: number;
} = {
  [Type.MULTIPLE_CHOICE]: 1,
  [Type.RATING_SCALE]: 2,
  [Type.EMAIL_COLLECTION]: 3,
  [Type.PHONE_COLLECTION]: 3,
  [Type.USERNAME_COLLECTION]: 3,
  [Type.NAME_COLLECTION]: 3,
  [Type.CUSTOM_IDENTIFY_COLLECTION]: 3,
};

const getEditableStepPriority = (step: { type: Type }): number =>
  editableStepPriority[step.type as EditableStep] ?? Infinity;

export function sortByEditableStepPriority(
  selectableSteps: SurveyState['steps'],
) {
  return selectableSteps.sort(
    (a, b) => getEditableStepPriority(a) - getEditableStepPriority(b),
  );
}

export const pairRankStep = () => ({
  type: Type.PAIR_RANK,
  question: 'Which of the following options is more important to you?',
  validation: { min: null, max: 10 },
});

export const simplePairRankStep = () => ({
  type: Type.SIMPLE_PAIR_RANK,
  question: 'Which of the following options is more important to you?',
  validation: { min: null, max: 10 },
});

export const orderRankStep = () => ({
  type: Type.ORDER_RANK,
  question:
    'Rank the following options from most important down to least important:',
});

export const dowdallOrderRankStep = () => ({
  type: Type.DOWDALL_ORDER_RANK,
  question:
    'Rank the following options from most important down to least important:',
});

export const pointsRankStep = () => ({
  type: Type.POINTS_RANK,
  question:
    'Allocate your points according to the options you care about most from the list below.',
});

export const agreementStep = () => ({
  type: Type.AGREEMENT_RANK,
  question: 'What is your opinion on the following statements?',
});

export const bestWorstStep = () => ({
  type: Type.BEST_WORST_RANK,
  question:
    'Pick the <strong>Best</strong> and <strong>Worst</strong> options from the list below',
});

export const profileStep = () => ({
  type: Type.PROFILE_RANK,
  question: 'Which of the following options appeals to you the most?',
});

export const multipleChoiceStep = () => ({
  type: Type.MULTIPLE_CHOICE,
  question: 'Question',
  answers: [{ answer: 'Option 1' }, { answer: 'Option 2' }],
  multiple: false,
});

export const ratingScaleStep = () => ({
  type: Type.RATING_SCALE,
  question: 'How important is ____ to you?',
  labels: { low: 'Least Important', high: 'Most Important' },
  multiple: false,
});

export const textResponseStep = () => ({
  type: Type.TEXT_RESPONSE,
  heading: "Do you have an opinion you didn't see while voting? Add it below:",
});

export const emailCollectionStep = () => ({
  type: Type.EMAIL_COLLECTION,
  heading:
    'Entering your email helps us follow up with you if we have any questions',
  required: false,
});

export const phoneCollectionStep = () => ({
  type: Type.PHONE_COLLECTION,
  heading: 'What is your phone number?',
  required: false,
});

export const usernameCollectionStep = () => ({
  type: Type.USERNAME_COLLECTION,
  heading: 'What is your username?',
  required: false,
});

export const nameCollectionStep = () => ({
  type: Type.NAME_COLLECTION,
  heading: 'What is your name?',
  required: false,
});

export const customIdentifyCollectionStep = () => ({
  type: Type.CUSTOM_IDENTIFY_COLLECTION,
  heading: 'What is your ___ ?',
  required: false,
});

export const headerStep = () => ({
  type: Type.HEADER,
  heading: 'Use Header Blocks to add new context or move onto new voting',
  description:
    "This is a plain text section to split up different survey blocks. For example, you could use it to say 'New Perspective: In the next voting section, I want you to think back to your perspective when you first signed-up to our product.'",
});

// This might look unnecessary but it prevents
// copied objects from becoming reactive
export const getStep = (step: Type) => {
  const steps = {
    [Type.PAIR_RANK]: pairRankStep(),
    [Type.SIMPLE_PAIR_RANK]: simplePairRankStep(),
    [Type.ORDER_RANK]: orderRankStep(),
    [Type.DOWDALL_ORDER_RANK]: dowdallOrderRankStep(),
    [Type.POINTS_RANK]: pointsRankStep(),
    [Type.AGREEMENT_RANK]: agreementStep(),
    [Type.BEST_WORST_RANK]: bestWorstStep(),
    [Type.PROFILE_RANK]: profileStep(),
    [Type.MULTIPLE_CHOICE]: multipleChoiceStep(),
    [Type.RATING_SCALE]: ratingScaleStep(),
    [Type.TEXT_RESPONSE]: textResponseStep(),
    [Type.EMAIL_COLLECTION]: emailCollectionStep(),
    [Type.PHONE_COLLECTION]: phoneCollectionStep(),
    [Type.USERNAME_COLLECTION]: usernameCollectionStep(),
    [Type.NAME_COLLECTION]: nameCollectionStep(),
    [Type.CUSTOM_IDENTIFY_COLLECTION]: customIdentifyCollectionStep(),
    [Type.HEADER]: headerStep(),
  };
  return steps[step];
};
